import moment from 'moment'

const state = {
  apiToken: null,
  tokenValidUntil: null,
  authenticated: false,
  products: [],
  productStocks: [],
  locale: {},
  modalVisibility: false,
  stockModalVisibility: false,
  instructionLinkModalVisibility: false,
  marketplaceModalVisibility: false,
  localeCurrencies: []
}

const getters = {
  getProducts: state => state.products,
  getProductStocks: state => state.productStocks,
  getApiToken: state => state.apiToken,
  getTokenValidUntil: state => state.tokenValidUntil,
  isAuthenticated: state => state.authenticated,
  getLocale: state => state.locale,
  getModalVisibility: state => state.modalVisibility,
  getStockModalVisibility: state => state.stockModalVisibility,
  getInstructionLinkModalVisibility: state => state.instructionLinkModalVisibility,
  getMarketplaceVisibility: state => state.marketplaceModalVisibility,
  getLocaleCurrencies: state => state.localeCurrencies
}

const actions = {
}

const mutations = {
  setProducts (state, products) {
    state.products = products
  },
  setApiToken (state, apiToken) {
    state.apiToken = apiToken
  },
  setTokenValidUntil (state, tokenValidUntil) {
    state.tokenValidUntil = tokenValidUntil
  },
  isAuthenticated (state) {
    const now = moment()
    const tokenExpireDate = moment(state.tokenValidUntil)
    state.authenticated = now <= tokenExpireDate
  },
  setLocale (state, locale) {
    state.locale = locale
  },
  setModalVisibility (state, modalVisibility) {
    state.modalVisibility = modalVisibility
  },
  setStockModalVisibility (state, stockModalVisibility) {
    state.stockModalVisibility = stockModalVisibility
  },
  setProductStocks (state, productStocks) {
    state.productStocks = productStocks
  },
  setInstructionLinkModalVisibility (state, instructionLinkModalVisibility) {
    state.instructionLinkModalVisibility = instructionLinkModalVisibility
  },
  setMarketplaceVisibility (state, marketplaceModalVisibility) {
    state.marketplaceModalVisibility = marketplaceModalVisibility
  },
  setLocaleCurrencies (state, localeCurrencies) {
    state.localeCurrencies = localeCurrencies
  },
  updateLocaleCurrencies (state, item) {
    state.localeCurrencies.push(item)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
