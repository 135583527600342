<template>
  <section class="flex flex-wrap w-row-offset-sm -mx-2">
    <div class="w-full md:w-1/3 order-last px-2 mb-4">
      <GridLayoutConfig
        v-if="editMode"
        :data.sync="data"
        :index="editingItem"
        :elements="elements"
        @add-item="addItem"
      />
    </div>
    <div class="w-full md:w-2/3 order-first flex-grow px-2 mb-4">
      <h2 v-if="title" class="text-2xl mb-2">
        {{ title }}
      </h2>
      <div v-bind:style="containerStyles" class="grid gap-4">
        <template v-if="itemCount > 0">
          <GridItem
            v-for="(item, index) in data.items"
            :key="item.key || index"
            :item="item"
            :index="index"
            :isStart="index === 0"
            :isEnd="index === (data.items.length - 1)"
            :elements="elements"
            :images="images"
            :edit-mode="editMode"
            :editing="index === editingItem"
            @edit-item="editItem"
            @move-item="moveItem"
            @delete-item="deleteItem"
          />
        </template>
        <div v-else-if="itemCount < 1" class="col-span-full border border-gray-300 text-gray-600 text-center px-4 py-6">
          No layout items added yet
        </div>
      </div>
      <NewGridItem
        v-if="editMode"
        class="w-full space-y-2 px-4 py-4 mt-4"
      >
        <font-awesome-icon :icon="['fas', 'plus-circle']" fixed-width />
        <span>Add a new block</span>
        <div class="flex space-x-2">
          <vue-select
            v-model="newItemElement"
            :options="elements"
            label-key="name"
          />
          <vue-button sm class="whitespace-nowrap" @click="addItem">
            Add
          </vue-button>
        </div>
      </NewGridItem>
    </div>
  </section>
</template>
<script>
import { merge } from 'lodash'
import ItemElements from '../elements.js'
import GridLayoutConfig from './GridLayoutConfig'
import GridItem from './GridItem'
import NewGridItem from './NewGridItem'

export default {
  name: 'GridLayoutContainer',

  components: {
    GridLayoutConfig,
    GridItem,
    NewGridItem
  },

  props: {
    data: {
      type: Object,
      required: true
    },

    images: {
      type: Object,
      required: false,
      default: () => { return null }
    },

    editMode: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    title: {
      type: String,
      required: false,
      default: () => { return null }
    }
  },

  data () {
    return {
      editingItem: null,
      newItemElement: false
    }
  },

  watch: {
    data: {
      deep: true,
      handler () {
        this.$emit('update:data', this.data)
      }
    },

    editMode: {
      handler () {
        this.editingItem = null
      }
    }
  },

  computed: {
    itemCount () {
      return this.data && this.data.items ? this.data.items.length : 0
    },

    containerStyles () {
      return {
        gridTemplateColumns: this.data && this.data.cols ? this.data.cols.join(' ') : '1fr',
        gridTemplateRows: this.data && this.data.rows ? this.data.rows.join(' ') : null,
        gridAutoColumns: this.data && this.data.autoCols ? this.data.autoCols : '1fr',
        gridAutoRows: this.data && this.data.autoRows ? this.data.autoRows : 'auto'
      }
    },

    elements () {
      return ItemElements
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.$store.dispatch('editor/getInfoBucketItems')
    },

    addItem () {
      if (typeof this.data.items === 'undefined') {
        this.$set(this.data, 'items', [])
      }
      const blockData = this.newItemElement && this.newItemElement.init ? this.newItemElement.init() : {}
      this.data.items.push(merge(
        {
          key: this.generateRandomKey()
        },
        {
          ...blockData,
          ...{
            component: this.newItemElement.component,
            element: this.newItemElement.key
          }
        }
      ))
    },

    editItem (index) {
      this.editingItem = index
    },

    moveItem (index, amount) {
      const newIndex = index + amount
      if (newIndex < 0 || newIndex === this.data.items.length) {
        return
      }
      const editing = index === this.editingItem
      const indexes = [index, newIndex].sort((a, b) => { return a - b })
      this.data.items.splice(indexes[0], 2, this.data.items[indexes[1]], this.data.items[indexes[0]])
      if (editing) {
        this.editingItem = newIndex
      }
    },

    deleteItem (index) {
      if (index === this.editingItem) {
        this.editingItem = null
      }
      this.data.items.splice(index, 1)
    },

    generateRandomKey (length = 16) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var key = ''
      for (var i = length - 1; i >= 0; i--) {
        const random = Math.floor(Math.random() * chars.length)
        key += chars.substring(random, random + 1)
      }
      return key
    }
  }
}
</script>
