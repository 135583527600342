var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'dialog fixed w-full h-full top-0 left-0 flex items-center justify-center': true,
    'dialog-active z-50': _vm.dialog,
    'opacity-0 pointer-events-none': !_vm.dialog
  }},[_c('div',{staticClass:"dialog-overlay absolute w-full h-full bg-gray-900 opacity-50"}),(_vm.dialog)?_c('div',{staticClass:"dialog-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"},[_c('div',{staticClass:"dialog-content py-4 text-left px-6"},[_c('div',{class:{
          'flex justify-between items-center': true,
          'pb-3': _vm.$slots.default
        }},[_vm._t("title"),_c('div',{staticClass:"dialog-close cursor-pointer z-50",on:{"click":_vm.close}},[_c('svg',{staticClass:"fill-current text-black",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"18","height":"18","viewBox":"0 0 18 18"}},[_c('path',{attrs:{"d":"M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"}})])])],2),_vm._t("default"),(_vm.$slots.action)?_c('div',{staticClass:"flex justify-end pt-2"},[_vm._t("action")],2):_vm._e()],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }