import { find } from 'lodash'
import axios from 'axios'
import ItemElements from '../elements.js'

const apiUrl = process.env.VUE_APP_API_BASE_URL + '/api/'

export default {
  namespaced: true,

  state: {
    elements: ItemElements,
    info_bucket_items: []
  },

  getters: {
    getElements: (state) => {
      return state.elements
    },

    getElementByKey: (state) => (key) => {
      return find(state.elements, { key }) || false
    }
  },

  mutations: {
    update (state, { key, value }) {
      state[key] = value
    },

    defineElements (state, data) {
      state.elements = data
    }
  },

  actions: {
    async getInfoBucketItems ({ commit }) {
      await axios.get(apiUrl + 'public/meta/settings_info_buckets', {
        params: {
          withImages: true
        }
      }).then(({ data }) => {
        commit('update', { key: 'info_bucket_items', value: data.data.value.buckets })
      })
    }
  }
}
