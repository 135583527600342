<template>
  <a
    v-if="$attrs.as === 'href'"
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="(loading || disabled) ? 'disabled' : false"
    :class="buttonClasses"
  >
    <font-awesome-icon
      v-if="icon && !loading"
      fixed-width
      :size="iconSize"
      :icon="icon"
      :class="{
        'ml-3': $slots.default && flip,
        'mr-3': $slots.default && !flip
      }"
    />
    <font-awesome-icon
      v-if="loading"
      fixed-width
      spin
      :size="iconSize"
      :icon="['fas', 'spinner-third']"
    />
    <slot />
  </a>
  <router-link
    v-else
    v-bind="$attrs"
    v-on="$listeners"
    :to="to"
    :disabled="(loading || disabled) ? 'disabled' : false"
    :class="buttonClasses"
  >
    <font-awesome-icon
      v-if="icon && !loading"
      fixed-width
      :size="iconSize"
      :icon="icon"
      :class="{
        'ml-3': $slots.default && flip,
        'mr-3': $slots.default && !flip
      }"
    />
    <font-awesome-icon
      v-if="loading"
      fixed-width
      spin
      :size="iconSize"
      :icon="['fas', 'spinner-third']"
    />
    <slot />
  </router-link>
</template>
<script type="text/javascript">
import HasButtonStyles from '@/mixins/HasButtonStyles'
export default {
  name: 'VueLink',

  mixins: [
    HasButtonStyles
  ],

  props: {
    to: {
      type: [String, Object],
      required: false,
      default: () => { return null }
    },

    tag: {
      type: String,
      required: false,
      default: () => { return null }
    }
  }
}
</script>
