<template>
  <div class="relative">
    <div :class="{
      'relative flex cursor-pointer': true,
      'bg-primary text-white': showChildren
    }">
      <router-link
        v-if="route"
        :to="route"
        :class="{
          'flex flex-grow items-center transition-colors px-2 py-6': true,
          'hover:bg-primary-light': !showChildren
        }"
        @click.native="$emit('reset-menu')"
      >
        <font-awesome-icon :icon="icon" class="mr-4" size="lg" fixed-width />
        <span>{{ label }}</span>
      </router-link>
      <a
        v-else
        :class="{
          'flex flex-grow items-center cursor-pointer transition-colors px-2 py-6': true,
          'hover:bg-primary-light': !showChildren
        }"
        @click="$emit('set-menu', unique)"
      >
        <font-awesome-icon :icon="icon" class="mr-4" size="lg" fixed-width />
        <span>{{ label }}</span>
      </a>
      <div v-if="addRoute" class="absolute right-0 flex items-center h-full mr-2">
        <router-link
          :to="addRoute"
          :title="`New ${label}`"
          :aria-label="`New ${label}`"
          class="flex justify-center items-center rounded-full bg-white text-shade-dark hover:bg-primary-light hover:shadow transition-colors px-2 py-2"
          @click.native="$emit('reset-menu')"
        >
          <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
        </router-link>
      </div>
      <div v-else-if="!addRoute && hasChildren" class="absolute right-0 flex items-center h-full pointer-events-none mr-4">
        <font-awesome-icon
          :icon="['fas', 'chevron-down']"
          :class="{
            'transition-transform transform-gpu': true,
            'scale-y-100': !showChildren,
            '-scale-y-100': showChildren
          }"
          fixed-width
        />
      </div>
    </div>
    <div v-if="hasChildren" v-show="showChildren" class="flex flex-col bg-shade-light border-l-4 border-primary w-full">
      <router-link
        v-for="(child, index) in children"
        :key="`nav-${index}`"
        :to="child.route"
        class="flex items-center hover:bg-primary-light transition-colors pl-4 px-2 py-4"
        @click.native="$emit('reset-menu')"
      >
        <font-awesome-icon v-if="child.icon" :icon="child.icon" class="mr-4" fixed-width />
        <span>{{ child.label }}</span>
      </router-link>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'VueNavigationItem',

  props: {
    unique: {
      type: String,
      required: true
    },

    label: {
      type: String,
      required: true
    },

    icon: {
      type: Array,
      required: true
    },

    route: {
      type: Object,
      required: false,
      default: () => { return null }
    },

    children: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    showChildren: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    addRoute: {
      type: Object,
      required: false,
      default: () => { return null }
    }
  },

  computed: {
    hasChildren () {
      return this.children.length > 0
    }
  }
}
</script>
