export default [
  {
    key: 'page-header-block',
    name: 'Page Header',
    component: 'PageHeaderBlock',
    render: () => import('./components/elements/page-header-block/PageHeaderBlockRender'),
    config: () => import('./components/elements/page-header-block/PageHeaderBlockConfig'),
    init: () => {
      return {
        grid: {
          colStart: 1,
          colSpan: -1
        },
        content: {}
      }
    }
  },
  {
    key: 'text-block',
    name: 'Text Block',
    component: 'TextBlock',
    render: () => import('./components/elements/text-block/TextBlockRender'),
    config: () => import('./components/elements/text-block/TextBlockConfig'),
    init: () => {
      return {
        grid: {
          colStart: 'auto',
          colSpan: 1
        },
        content: {}
      }
    }
  },
  {
    key: 'image-block',
    name: 'Image Block',
    component: 'ImageBlock',
    render: () => import('./components/elements/image-block/ImageBlockRender'),
    config: () => import('./components/elements/image-block/ImageBlockConfig'),
    init: () => {
      return {
        grid: {
          colStart: 1,
          colSpan: -1
        },
        content: {}
      }
    }
  },
  {
    key: 'cut-container-block',
    name: 'Photo/Content Block',
    component: 'CutContainerBlock',
    render: () => import('./components/elements/cut-container-block/CutContainerBlockRender'),
    config: () => import('./components/elements/cut-container-block/CutContainerBlockConfig'),
    init: () => {
      return {
        grid: {
          colStart: 1,
          colSpan: -1
        },
        content: {}
      }
    }
  },
  {
    key: 'cut-container-contact-form-block',
    name: 'Photo/Content Contact Form Block',
    component: 'CutContatinerContactFormBlock',
    render: () => import('./components/elements/cut-container-contact-form-block/CutContainerContactFormBlockRender'),
    config: () => import('./components/elements/cut-container-contact-form-block/CutContainerContactFormBlockConfig'),
    init: () => {
      return {
        grid: {
          colStart: 1,
          colSpan: -1
        },
        content: {}
      }
    }
  },
  {
    key: 'info-bucket-block',
    name: 'Info Buckets Block',
    component: 'InfoBucketBlock',
    render: () => import('./components/elements/info-buckets-block/InfoBucketBlockRender'),
    config: () => import('./components/elements/info-buckets-block/InfoBucketBlockConfig'),
    init: () => {
      return {
        grid: {
          colStart: 1,
          colSpan: -1
        },
        content: {
          contact_name_show: true,
          contact_email_show: true,
          contact_phone_show: true,
          contact_message_show: true
        }
      }
    }
  },
  {
    key: 'special-page-content-block',
    name: 'Special Page Content',
    component: 'SpecialPageContentBlock',
    render: () => import('./components/elements/special-page-content-block/SpecialPageContentBlockRender'),
    init: () => {
      return {
        grid: {
          colStart: 1,
          colSpan: -1
        },
        content: {}
      }
    }
  },
  {
    key: 'spacer-block',
    name: 'Spacer',
    component: 'SpacerBlock',
    render: () => import('./components/elements/spacer-block/SpacerBlockRender'),
    config: () => import('./components/elements/spacer-block/SpacerBlockConfig'),
    init: () => {
      return {
        grid: {
          colStart: 1,
          colSpan: -1
        },
        content: {
          spacing: 100
        }
      }
    }
  },
  {
    key: 'iframe-block',
    name: 'Iframe Block',
    component: 'IFrameBlock',
    render: () => import('./components/elements/iframe-block/IFrameBlockRender'),
    config: () => import('./components/elements/iframe-block/IFrameBlockConfig'),
    init: () => {
      return {
        grid: {
          colStart: 1,
          colSpan: -1
        },
        content: {
          height: '50vh',
          url: ''
        }
      }
    }
  },
  {
    key: 'hidden-block',
    name: 'Hidden Block',
    component: null,
    render: () => import('./components/elements/hidden-block/HiddenBlockRender'),
    config: () => import('./components/elements/hidden-block/HiddenBlockConfig'),
    init: () => {
      return {
        grid: {
          colStart: 1,
          colSpan: -1
        },
        content: {}
      }
    }
  },
  {
    key: 'products-set-block',
    name: 'Products Set',
    component: null,
    render: () => import('./components/elements/products-set-block/ProductsSetBlockRender'),
    config: () => import('./components/elements/products-set-block/ProductsSetBlockConfig'),
    init: () => {
      return {
        grid: {
          colStart: 1,
          colSpan: -1
        },
        content: {
          set: null
        }
      }
    }
  }
]
