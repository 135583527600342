import Vue from 'vue'
import moment from 'moment'

Vue.mixin({
  computed: {
    $auth () {
      return {
        isAuthenticated: this.$store.getters['auth/isAuthenticated'],
        user: this.$store.getters['user/user'] || {},
        userCan: (permission) => {
          return this.$store.getters['user/hasPermission'](permission)
        },
        getUser: () => {
          return this.$store.dispatch('user/getUser')
        },
        updateUser: (obj) => {
          return this.$store.dispatch('user/updateUser', obj)
        },
        login: (obj) => {
          return this.$store.dispatch('auth/login', obj)
        },
        logout: () => {
          this.$store.dispatch('auth/logout')
        }
      }
    }
  },

  methods: {
    $currency (value) {
      return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value)
    },

    $moment (date, utc = false) {
      return utc ? moment.utc(date) : moment(date)
    },

    $momentDate (date) {
      return this.$moment(date).format('Do MMM YYYY')
    },

    $momentDateTime (date, utc = false) {
      return this.$moment(date, utc).format('YYYY-MM-DD HH:mm:ss')
    },

    $momentDateTimeFormat (date, utc = false) {
      return this.$moment(date, utc).format('YYYY-MM-DD')
    },

    $momentDateTimeHuman (date, utc = false) {
      return this.$moment(date, utc).format('Do MMM YYYY - HH:mm:ss')
    }
  }
})

Vue.directive('user-can', {
  inserted (el, binding, vnode) {
    if (!vnode.context.$auth.userCan(binding.arg)) {
      vnode.elm.parentElement.removeChild(vnode.elm)
    }
  }
})

Vue.directive('user-cant', {
  inserted (el, binding, vnode) {
    if (vnode.context.$auth.userCan(binding.arg)) {
      vnode.elm.parentElement.removeChild(vnode.elm)
    }
  }
})
