import axios from 'axios'

// initial state
const state = {
  user: {}
}

// getters
const getters = {
  user: (state) => {
    return state.user
  },

  userPermissions: (state) => {
    var permissions = []
    state.user.data.roles.forEach(role => {
      role.permissions.forEach(permission => {
        permissions.push(permission.name)
      })
    })
    return permissions
  },

  hasPermission: (state, getters) => (permission) => {
    var hasRole = false
    getters.userPermissions.forEach(userPermission => {
      if (userPermission === permission) {
        hasRole = true
      }
    })
    return hasRole
  }
}

// actions
const actions = {
  getUser ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/api/my/user`, {
        params: {
          with: ['roles.permissions']
        }
      })
        .then(response => {
          commit('setUser', response.data.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateUser ({ commit, getters }, obj) {
    return new Promise((resolve, reject) => {
      this._vm.$api.put('my/user', { ...{ with: ['roles.permissions'] }, ...obj })
        .then(({ data: user }) => {
          commit('setUser', user)
          resolve(user)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  clearUser ({ commit }) {
    commit('clearUser')
  }
}

// mutations
const mutations = {
  setUser (state, user) {
    state.user = user
  },

  updateUserValue (state, keyValue) {
    state.user[keyValue.key] = keyValue.value
  },

  clearUser (state) {
    state.user = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
