<template>
  <div class="group flex">
    <flat-pickr
      v-model="localValue"
      v-bind="$attrs"
      :config="config"
      :name="prop"
      :class="{
        'form-control block w-full text-sm bg-white text-shade-dark border border-shade rounded-r-none border-r-0 group-focus:border-primary group-hover:border-shade-dark px-2 py-3 outline-none transition-all': true,
        'rounded': !square,
        'rounded-none': square
      }"
      placeholder="Select date"
    />
    <vue-button
      dark
      prepend
      append
      @click="reset"
    >
      Empty
    </vue-button>
    <vue-button
      success
      append
      @click="now"
    >
      Now
    </vue-button>
  </div>
</template>
<script type="text/javascript">
import HasVModel from '@/mixins/HasVModel'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  name: 'VueInput',

  components: {
    flatPickr
  },

  mixins: [HasVModel],

  props: {
    options: {
      type: Object,
      required: false,
      default: () => { return null }
    },

    prop: {
      type: String,
      required: true
    },

    prepend: {
      type: [Boolean, String],
      required: false,
      default: () => { return null }
    },

    append: {
      type: [Boolean, String],
      required: false,
      default: () => { return null }
    },

    square: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  computed: {
    config () {
      return {
        ...{
          dateFormat: 'Y-m-d H:i:S',
          defaultHour: 0,
          enableTime: true,
          enableSeconds: true,
          time_24hr: true
        },
        ...this.options,
        ...{
          parseDate: (dateString, dateFormat) => {
            return this.$momentDateTime(dateString, false)
          },
          formatDate: (dateString, dateFormat) => {
            return this.$momentDateTime(dateString, true)
          }
        }
      }
    }
  },

  methods: {
    reset () {
      this.localValue = null
    },

    now () {
      this.localValue = Date.now()
    }
  }
}
</script>
