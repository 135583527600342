import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import error from '@/store/modules/error'
import form from '@/store/modules/form'
import prepaidForgeApi from '@/store/modules/prepaidForgeApi'
import Api from '@/api/'

Vue.use(Vuex)

Vue.use(Api, { baseUrl: process.env.VUE_APP_API_BASE_URL })

export default new Vuex.Store({
  modules: {
    auth,
    user,
    error,
    form,
    prepaidForgeApi
  },
  plugins: [createPersistedState({
    paths: [
      'user.user',
      'form.form'
    ]
  })]
})
