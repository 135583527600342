<template>
<div
  :class="{
    'dialog fixed w-full h-full top-0 left-0 flex items-center justify-center': true,
    'dialog-active z-50': dialog,
    'opacity-0 pointer-events-none': !dialog
  }"
>
  <div class="dialog-overlay absolute w-full h-full bg-gray-900 opacity-50" />
  <div
    v-if="dialog"
    class="dialog-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto"
  >
    <div class="dialog-content py-4 text-left px-6">
      <div
        :class="{
          'flex justify-between items-center': true,
          'pb-3': $slots.default
        }"
      >
        <slot name="title" />
        <div
          class="dialog-close cursor-pointer z-50"
          @click="close"
        >
          <svg class="fill-current text-black" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
        </div>
      </div>
      <slot />
      <div v-if="$slots.action" class="flex justify-end pt-2">
        <slot name="action" />
      </div>
    </div>
  </div>
</div>
</template>
<script type="text/javascript">
export default {
  name: 'VueDialog',

  props: {
    dialog: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  methods: {
    close () {
      this.$emit('update:dialog', false)
    }
  }
}
</script>
<style type="text/css">
.dialog {
  transition: opacity 0.25s ease;
}
.dialog-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}
</style>
