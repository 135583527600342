import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Api from './api/'
import VueGridLayoutDesigner from '@/plugins/vue-grid-layout-designer/main.js'
import VueYoastSeo from '@/plugins/vue-yoast-seo/main.js'
import websockets from './websockets.js'
import Echo from 'laravel-echo'
import './registerServiceWorker'
import './assets/styles/index.scss'
import '@/components.js'
import '@/mixins.js'
import '@/icons.js'
import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

axios.defaults.withCredentials = true

Vue.config.productionTip = false

Vue.use(Api, { baseUrl: process.env.VUE_APP_API_BASE_URL })
Vue.use(VueGridLayoutDesigner, store)
Vue.use(VueYoastSeo)
Vue.use(ToastPlugin, {
  duration: 5000,
  dismissible: true,
  position: 'top-right'
})

if (websockets.client) {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    client: websockets.client
  })
}

new Vue({
  router,
  store,
  mounted () {
    this.$auth.getUser()
  },
  render: h => h(App)
}).$mount('#app')
