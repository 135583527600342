<template>
  <div class="flex flex-col space-y-4">
    <div class="space-y-2">
      <h2 class="text-2xl">
        SEO
      </h2>
      <vue-data
        v-model="model.value.seo.keyphrase"
        :value="model.value.seo.keyphrase"
        :edit="edit"
        label="Focus Keyphrase"
      />
      <vue-data
        v-model="model.value.seo.title"
        :value="model.value.seo.title"
        :edit="edit"
        label="Page Title"
      />
      <vue-data
        v-model="model.value.seo.description"
        :value="model.value.seo.description"
        :edit="edit"
        label="Page Description"
        type="textarea"
      />
    </div>
    <content-assessor
      :title="seo.title"
      :description="seo.description"
      :keyword="seo.keyword"
      :url="seo.url"
      :permalink="seo.permalink"
      :locale="seo.locale"
      :text="textToAnalyse"
    />
    <seo-assessor
      :title="seo.title"
      :description="seo.description"
      :keyword="seo.keyword"
      :url="seo.url"
      :permalink="seo.permalink"
      :locale="seo.locale"
      :text="textToAnalyse"
    />
    <snippet-preview
      :title="seo.title"
      :description="seo.description"
      :url="seo.url"
      :baseUrl="seo.base_url"
      @update:titleWidth="(value) => { titleWidth = value }"
      @update:titleLengthPercent="(value) => { titleLengthPercent = value }"
      @update:descriptionLengthPercent="(value) => { descriptionLengthPercent = value }"
    />
  </div>
</template>
<script>
import SnippetPreview from './SnippetPreview'
import ContentAssessor from './ContentAssessor'
import SeoAssessor from './SeoAssessor'
import markdownIt from 'markdown-it'
import { map, isEmpty } from 'lodash'

export default {
  name: 'SeoSection',

  components: {
    SnippetPreview,
    ContentAssessor,
    SeoAssessor
  },

  props: {
    model: {
      type: [Array, Object],
      required: true
    },

    edit: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  data () {
    return {
      titleWidth: 0,
      titleLengthPercent: 0,
      descriptionLengthPercent: 0,
      md: new markdownIt() // eslint-disable-line new-cap
    }
  },

  computed: {
    seo () {
      return {
        title: this.model.value.seo.title,
        description: this.model.value.seo.description,
        keyword: this.model.value.seo.keyphrase,
        url: `/${this.model.value.slug}`,
        base_url: process.env.VUE_APP_FRONTEND_URL,
        permalink: `${process.env.VUE_APP_API_BASE_URL}${this.model.value.slug}`,
        locale: this.$route.params.locale ? this.$route.params.locale.replace('-', '_') || 'en_GB' : 'en_GB'
      }
    },

    textToAnalyse () {
      const input = [
        ...[
          this.model.value.title ? `<h1>${this.model.value.title}</h1>` : null
        ],
        ...(this.model.value && this.model.value.layout && this.model.value.layout.items
          ? map(this.model.value.layout.items, (item) => {
            const items = [
              (item.content.title ? `<h2>${item.content.title}</h2>` : null),
              (item.content.content ? this.md.render(item.content.content) : null),
              (item.content.images && Object.values(item.content.images).length > 0 ? map(item.content.images, (image, index) => {
                return image && typeof image === 'object' ? `<img src="${image.medium}" alt="${item.content[`${index}_alt`] || ''}" />` : null
              }) : null),
              (item.element === 'info-bucket-block' && item.content.opened ? map(this.$store.state.editor.info_bucket_items, (bucket) => {
                return `<h2>${bucket.title}</h2>${this.md.render(bucket.content)}`
              }) : null)
            ]
            return items.filter((i) => { return !isEmpty(i) }).join()
          }) : []
        )
      ]
      const output = input.filter((i) => { return !isEmpty(i) }).join()
      return output
    }
  }
}
</script>
