<template>
<div>
  <label
    v-if="label"
    class="text-sm font-medium"
  >
    {{ label }}
  </label>
  <div class="relative">
    <select
      ref="input"
      v-model="localValue"
      v-bind="$attrs"
      :class="{
        'block appearance-none w-full bg-white border border-shade text-sm outline-none focus:border-primary hover:border-shade-dark justify-between items-center transition-all py-3 px-3': true,
        'rounded': !square,
        'rounded-none': square,
        'rounded-l-none border-l-0': prepend,
        'rounded-r-none border-r-0': append
      }"
      @change="localErrors = []"
    >
      <option value="" disabled>
        Please select an option
      </option>
      <option
        v-for="(option, key) in options"
        :key="key"
        :value="optionValue(option)"
        :disabled="disableOnNull ? !optionValue(option) : false"
      >
        {{ optionLabel(option) }}
      </option>
    </select>
    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
      <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
    </div>
  </div>
  <div>
    <span
      v-for="(error, errorKey) in localErrors"
      :key="errorKey"
      class="text-red-600 text-xs font-medium mr-1">{{ error }}</span>
  </div>
</div>
</template>
<script>
import HasVModel from '@/mixins/HasVModel'
import HasErrors from '@/mixins/HasErrors'

export default {
  name: 'VueSelect',

  mixins: [HasVModel, HasErrors],

  props: {
    label: {
      type: String,
      required: false,
      default: () => { return null }
    },

    square: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    prepend: {
      type: [Boolean, String],
      required: false,
      default: () => { return null }
    },

    append: {
      type: [Boolean, String],
      required: false,
      default: () => { return null }
    },

    labelKey: {
      type: [String, Function],
      required: false,
      default: () => { return null }
    },

    valueKey: {
      type: String,
      required: false,
      default: () => { return null }
    },

    dropdownLabel: {
      type: [Boolean, Function],
      required: false,
      default: () => { return false }
    },

    options: {
      type: Array,
      required: true
    },

    disableOnNull: {
      type: Boolean,
      required: false,
      default: () => { return true }
    }
  },

  methods: {
    optionValue (option) {
      if (this.valueKey) {
        return option[this.valueKey]
      }
      return option
    },

    optionLabel (option) {
      if (this.dropdownLabel && typeof this.dropdownLabel === 'function') {
        return this.dropdownLabel(option)
      }
      if (this.labelKey && typeof this.labelKey === 'function') {
        return this.labelKey(option)
      }
      if (this.labelKey) {
        return option[this.labelKey] ?? '(Not Assigned)'
      }
      return option
    }
  }
}
</script>
