<template>
  <section class="space-y-2">
    <button class="flex items-center justify-between w-full py-1" @click="expanded = !expanded">
      <h4 class="text-lg">Snippet Preview</h4>
      <font-awesome-icon
        :icon="['fas', 'chevron-down']"
        :class="{
          'transform-gpu transition-transform': true,
          'scale-y-100': !expanded,
          '-scale-y-100': expanded
        }"
        fixed-width
      />
    </button>
    <div v-show="expanded" class="flex flex-col space-y-2">
      <div class="relative bg-white shadow px-4 py-4">
        <div ref="url" class="text-xs">
          {{ baseUrl + url.slice(1) }}
        </div>
        <div  class="text-lg text-blue-600">
          {{ title }}
        </div>
        <div ref="title" class="absolute h-0 text-lg overflow-y-hidden whitespace-nowrap" aria-hidden="true">
          {{ title }}
        </div>
        <div ref="description" class="text-gray-800 text-sm">
          {{ description.substr(0, maxDescriptionLength) }}
          {{ description.length > maxDescriptionLength ? '...' : '' }}
        </div>
      </div>
      <div class="flex items-center justify-between">
        <span>Title: {{ titleLength }}px / {{ maxTitleWidth }}px ({{ titleLengthPercent.toFixed(2) }}%)</span>
        <span
          v-if="titleScore"
          :class="{
            'rounded-full font-bold text-white text-sm uppercase px-2 py-1': true,
            'bg-green-700': titleScore === 'good',
            'bg-yellow-500': titleScore === 'ok',
            'bg-red-700': titleScore === 'bad'
          }"
        >
          {{ titleScore }}
        </span>
      </div>
      <div class="flex items-center justify-between">
        <span>Description: {{ description.length }} / {{ maxDescriptionLength }} ({{ descriptionLengthPercent.toFixed(2) }}%)</span>
        <span
          v-if="descriptionScore"
          :class="{
            'rounded-full font-bold text-white text-sm uppercase px-2 py-1': true,
            'bg-green-700': descriptionScore === 'good',
            'bg-yellow-500': descriptionScore === 'ok',
            'bg-red-700': descriptionScore === 'bad'
          }"
        >
          {{ descriptionScore }}
        </span>
      </div>
    </div>
  </section>
</template>
<script>
import { rateTitleLength, rateMetaDescLength } from '../utils.js'

export default {
  name: 'SnippetPreview',

  props: {
    title: {
      type: String,
      default: ''
    },

    url: {
      type: String,
      default: ''
    },

    description: {
      type: String,
      default: ''
    },

    metaHeight: {
      type: Number,
      default: 0
    },

    placeholder: {
      type: Object,
      default () {
        return {
          title: 'This is an example title',
          metaDesc: 'This is meta description',
          urlPath: '/example-post'
        }
      }
    },

    defaultValue: {
      type: Object,
      default () {
        return {
          title: '',
          metaDesc: ''
        }
      }
    },

    addTrailingSlash: {
      type: Boolean,
      default: true
    },

    metaDescriptionDate: {
      type: String,
      default: ''
    },

    previewMode: {
      type: String,
      default: 'desktop'
    },

    baseUrl: {
      type: String,
      default: 'http://example.com'
    },

    hasProgressSupport: {
      type: Boolean,
      default: true
    },

    maxTitleWidth: {
      type: Number,
      default: 600
    },

    maxDescriptionLength: {
      type: Number,
      default: 320
    }
  },

  data () {
    return {
      expanded: true,
      titleLength: 0,
      titleLengthPercent: 0,
      titleScore: 0,
      descriptionLengthPercent: 0,
      descriptionScore: 0
    }
  },

  computed: {
    description_ () {
      const desc = this.description
      const maxLength = this.maxDescriptionLength
      const more = desc.length > maxLength ? ' ...' : ''
      return desc.substr(0, maxLength) + more
    }
  },

  watch: {
    title () {
      this.calculate()
    },

    description () {
      this.calculate()
    }
  },

  mounted () {
    this.calculate()
    this.onTitleUpdated()
  },

  methods: {
    calculate () {
      this.titleLength = this.$refs.title.offsetWidth
      this.titleLengthPercent = ((this.$refs.title.offsetWidth / this.maxTitleWidth) * 100)
      this.titleScore = this.calculateTitleRating(this.$refs.title.offsetWidth)

      this.descriptionLengthPercent = ((this.description.length / this.maxDescriptionLength) * 100)
      this.descriptionScore = this.calculateDescriptionRating(this.description.length)
    },

    calculateTitleRating (length) {
      let rating = ''

      if ((length > 0 && length <= 399) || length > 600) {
        rating = 'ok'
      } else if (length >= 400 && length <= 600) {
        rating = 'good'
      } else {
        rating = 'bad'
      }

      return rating
    },

    calculateDescriptionRating (length) {
      let rating = ''

      if ((length > 0 && length < 120) || length > 320) {
        rating = 'ok'
      } else if (length >= 120 && length <= 320) {
        rating = 'good'
      } else {
        rating = 'bad'
      }

      return rating
    },

    titleRate () {
      const el = this.$refs.title
      if (el) {
        return rateTitleLength(this.$refs.title.offsetWidth)
      }
      return ''
    },

    descriptionRate () {
      return rateMetaDescLength(this.description.length)
    },

    onTitleUpdated () {
      const w = this.$refs.title.offsetWidth
      this.$emit('update:titleWidth', w)
      this.$emit('update:titleLengthPercent', (w / this.maxTitleWidth) * 100)
    }
  }
}
</script>
