<template>
  <div
    v-bind="$attrs"
    :class="{
      'px-3 py-2 rounded-sm': true,
      'border border-shade': border
    }"
  >
    <slot />
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'VueCard',

  props: {
    border: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  }
}
</script>
