<template>
  <div class="group flex">
    <div
      v-if="prepend && typeof prepend === 'string'"
      :class="{
        'flex items-center font-medium bg-gray-200 border border-shade border-r-0 group-focus:border-primary group-hover:border-shade-dark rounded-l transition-all': true,
        'text-xs px-2': small,
        'text-sm px-3': !small
      }"
    >
      {{ prepend }}
    </div>
    <input
      ref="input"
      v-model="localValue"
      v-bind="$attrs"
      :class="{
        'block w-full text-sm bg-white text-shade-dark border border-shade group-focus:border-primary group-hover:border-shade-dark outline-none transition-all': true,
        'rounded': !square,
        'rounded-none': square,
        'rounded-l-none border-l-0': prepend,
        'rounded-r-none border-r-0': append,
        'px-1 py-2': small,
        'px-2 py-3': !small
      }"
    >
    <div
      v-if="append && typeof append === 'string'"
      :class="{
        'flex items-center font-medium bg-gray-200 border border-shade border-l-0 group-focus:border-primary group-hover:border-shade-dark rounded-r transition-all': true,
        'text-xs px-2': small,
        'text-sm px-3': !small
      }"
    >
      {{ append }}
    </div>
  </div>
</template>
<script type="text/javascript">
import HasVModel from '@/mixins/HasVModel'

export default {
  name: 'VueInput',

  mixins: [HasVModel],

  props: {
    prepend: {
      type: [Boolean, String],
      required: false,
      default: () => { return null }
    },

    append: {
      type: [Boolean, String],
      required: false,
      default: () => { return null }
    },

    square: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    small: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  mounted () {
    if ('autofocus' in this.$attrs) {
      this.focus()
    }
  },

  methods: {
    focus () {
      this.$refs.input.focus()
    }
  }
}
</script>
<style scoped>
  input:invalid {
    @apply border-red-600;
  }
</style>
