<template>
  <div class="bg-white w-full rounded">
    <div class="flex flex-col px-1 py-1">
      <vue-button
        text
        sm
        @click.native="$emit('update:filter-key', null)"
      >
        Back
      </vue-button>
      <template v-for="operator in filterOperators">
        <vue-button
          v-if="operator.usedIn.includes(filterKey.type)"
          text
          sm
          flip
          space
          :key="operator.key"
          :icon="['fas', 'arrow-circle-right']"
          @click="$emit('update:filter-operator', operator)"
        >
          <span>{{ filterKey.label }} <strong>{{ operator.label }}</strong></span>
        </vue-button>
      </template>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'TableFilterOperators',

  props: {
    filterKey: {
      required: true
    }
  },

  data () {
    return {
      filterOperator: {},
      filterOperators: [
        {
          label: 'is',
          key: 'is',
          usedIn: ['String', 'Select']
        },
        {
          label: 'is not',
          key: 'is_not',
          usedIn: ['String', 'Select']
        },
        {
          label: 'starts with',
          key: 'starts_with',
          usedIn: ['String']
        },
        {
          label: 'ends with',
          key: 'ends_with',
          usedIn: ['String']
        },
        {
          label: 'contains',
          key: 'contains',
          usedIn: ['String']
        }
      ]
    }
  }
}
</script>
