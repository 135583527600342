<template>
  <div
    :style="itemStyles"
    class="group relative flex items-stretch overflow-hidden"
  >
    <div
      ref="element"
      :class="{
        'w-full border-2 border-dashed rounded transition-colors': true,
        'border-gray-400': editing,
        'border-transparent': !editing
      }"
    >
      <template v-if="element">
        <span class="hidden absolute top-0 inset-x-0 text-sm text-center opacity-50 pointer-events-none py-2">{{ element ? element.name : 'Unknown Element' }}</span>
        <component
          :is="element.render"
          :item="item"
        />
      </template>
      <span v-else>Element Missing</span>
    </div>
    <div v-if="editMode" ref="settings" class="absolute inset-0 flex items-center justify-center text-white bg-black bg-opacity-75 rounded opacity-0 group-hover:opacity-100 transition-opacity px-4 py-4">
      <div v-if="!isStart" class="absolute top-0 inset-x-0 flex justify-center">
        <vue-button
          :icon="['fas', 'chevron-up']"
          sm
          @click="$emit('move-item', index, -1)"
        />
      </div>
      <div v-if="!isEnd" class="absolute bottom-0 inset-x-0 flex justify-center">
        <vue-button
          :icon="['fas', 'chevron-down']"
          sm
          @click="$emit('move-item', index, 1)"
        />
      </div>
      <div class="space-x-2">
        <button class="inline-flex items-center pointer-cursor appearance-none space-x-2 rounded-sm px-2 py-1" @click="editItem">
          <font-awesome-icon :icon="['fas', 'edit']" fixed-width />
          <span>Edit</span>
        </button>
        <button class="inline-flex items-center pointer-cursor appearance-none space-x-2 rounded-sm px-2 py-1" @click="deleteItem">
          <font-awesome-icon :icon="['fas', 'trash']" fixed-width />
          <span>Delete</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { find, mapKeys, mapValues } from 'lodash'

export default {
  name: 'GridItem',

  props: {
    item: {
      type: Object,
      required: true
    },

    index: {
      required: true
    },

    editMode: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    isStart: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    isEnd: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    editing: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    elements: {
      type: Array,
      required: true
    },

    images: {
      type: Object,
      required: false,
      default: () => { return null }
    }
  },

  computed: {
    itemStyles () {
      return {
        minHeight: this.editMode ? '120px' : 'unset',
        backgroundColor: this.item.backgroundColor ?? 'white',
        gridColumn: this.item.grid ? `${this.item.grid.colStart ? this.item.grid.colStart : 'auto'} / ${this.item.grid.colSpan > 0 ? 'span' : ''} ${this.item.grid.colSpan ? this.item.grid.colSpan : 'auto'}` : 'auto / span auto',
        gridRow: this.item.grid ? `${this.item.grid.rowStart ? this.item.grid.rowStart : 'auto'} / span ${this.item.grid.rowSpan ? this.item.grid.rowSpan : 'auto'}` : 'auto / span auto'
      }
    },

    element () {
      const element = find(this.elements, { key: this.item.element })
      return typeof element !== 'undefined' ? element : null
    }
  },

  mounted () {
    this.mapImages()
  },

  methods: {
    mapImages () {
      this.item.content.images = mapValues(mapKeys(this.item.content.images, (image, index) => {
        return index
      }), (value, id) => {
        return this.images[id] || null
      })
    },

    editItem () {
      this.$emit('edit-item', this.index)
    },

    deleteItem () {
      this.$emit('delete-item', this.index)
    }
  }
}
</script>
