// initial state
const state = {
  form: {
    name: 'sdsd'
  }
}

// getters
const getters = {
  form: (state) => {
    return state.form
  }
}

// actions
const actions = {
  //
}

// mutations
const mutations = {
  updateFormValue (state, keyValue) {
    state.form[keyValue.key] = keyValue.value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
