<template>
  <div class="bg-white w-full rounded">
    <div class="flex flex-col px-1 py-1">
      <vue-button
        text
        sm
        @click.native="$emit('update:show-menu', false)"
      >
        Close
      </vue-button>
      <vue-button
        v-for="key in filterKeys"
        text
        sm
        flip
        space
        :key="key.key"
        :icon="['fas', 'arrow-circle-right']"
        @click="$emit('update:filter-key', key)"
      >
        <span>Filter by <strong>{{ key.label }}</strong></span>
      </vue-button>
    </div>
  </div>
</template>
<script type="text/javascript">
import { isEmpty } from 'lodash'

export default {
  name: 'TableFilterKeys',

  props: {
    filterKeys: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },

  computed: {
    visible () {
      return !isEmpty(this.filterKey)
    }
  },

  data () {
    return {
      filterKey: {}
    }
  }
}
</script>
