import Vue from 'vue'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faBars,
  faBox,
  faBoxes,
  faChartBar,
  faCheckCircle,
  faChevronDown,
  faCogs,
  faDesktop,
  faEdit, faEye, faFile, faFileDownload, faFileUpload, faGlobe,
  faListUl,
  faMicrochip,
  faPlus,
  faReceipt,
  faRss,
  fas,
  faSave,
  faSortAmountDown,
  faSortAmountUp, faSync, faSyncAlt, faTachometerAlt,
  faTimesCircle,
  faTrash, faTruckLoading,
  faUser, faUserCircle,
  faUsers,
  faUserTie,
  faVideo
} from '@fortawesome/free-solid-svg-icons'

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

// Add icons as desired. See documentation here: https://github.com/FortAwesome/vue-fontawesome
library.add(
  fas,
  faBars,
  faPlus,
  faBox,
  faBoxes,
  faReceipt,
  faMicrochip,
  faDesktop,
  faUsers,
  faCogs,
  faUser,
  faUserTie,
  faChartBar,
  faEdit,
  faTrash,
  faListUl,
  faCheckCircle,
  faSave,
  faVideo,
  faRss,
  faTimesCircle,
  faArrowCircleLeft,
  faArrowCircleRight,
  faChevronDown,
  // faSpinnerThird,
  faSortAmountUp,
  faSortAmountDown,
  // faSortAlt,
  faUserCircle,
  faTachometerAlt,
  faSyncAlt,
  faSync,
  faEye,
  // faBoxUsd,
  faTruckLoading,
  // faJoystick,
  faGlobe,
  faFile,
  faFileUpload,
  faFileUpload,
  faFileDownload
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
