export default {
  props: {
    text: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    iconSize: {
      type: String,
      required: false,
      default: () => { return 'lg' }
    },

    disabled: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    loading: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    icon: {
      type: Array,
      required: false,
      default: () => { return null }
    },

    prepend: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    append: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    outline: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    selected: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    bold: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    flip: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    space: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    lg: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    sm: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    xs: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    success: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    warning: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    error: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    custom: {
      type: Boolean,
      required: false,
      default: () => { return false }
    }
  },

  computed: {
    buttonClasses () {
      return {
        'flex items-center rounded text-white transition-all': true,
        'opacity-50 cursor-not-allowed': this.loading || this.disabled,
        'border-transparent': !this.outline,
        'font-bold': this.bold,
        'justify-between': this.space,
        'flex-row-reverse': this.flip,
        'rounded-r-none': this.prepend,
        'rounded-l-none': this.append,

        // Sizes
        'py-2 px-3 border-2': !this.sm && !this.xs && !this.lg,
        // Large
        'py-3 px-4 border-2': this.lg,
        // Small
        'py-1 px-2 text-sm border-2': this.sm,
        // X-Small
        'py-1 px-1 text-xs border': this.xs,

        // Primary
        'bg-primary hover:bg-primary-dark transition-all': (!this.custom && !this.success && !this.error && !this.warning) && !this.outline && !this.text,
        // Outline
        'border-primary hover:bg-primary text-primary hover:text-primary-contrast transition-all': (!this.custom && !this.success && !this.error && !this.warning) && this.outline,
        // Text
        'text-primary hover:text-primary-contrast hover:bg-primary transition-all': (!this.custom && !this.success && !this.error && !this.warning) && !this.selected && this.text,
        // Selected
        'bg-primary text-contrast transition-all': (!this.custom && !this.success && !this.error && !this.warning) && this.selected,

        // Green
        'bg-green-600 hover:bg-green-700': this.success && !this.outline && !this.text,
        // Outline
        'border-green-600 hover:bg-green-600 text-green-600 hover:text-white': this.success && this.outline,
        // Text
        'hover:bg-green-200 text-green-600': this.success && this.text,
        // Selected
        'bg-green-600 text-white': this.success && this.selected,

        // Orange
        'bg-orange-600 hover:bg-orange-700': this.warning && !this.outline && !this.text,
        // Outline
        'border-orange-600 hover:bg-orange-600 text-orange-600 hover:text-white': this.warning && this.outline,
        // Text
        'hover:bg-orange-200 text-orange-600': this.warning && this.text,
        // Selected
        'bg-orange-600 text-white': this.warning && this.selected,

        // Error
        'bg-red-600 hover:bg-red-700 text-white': this.error && !this.outline && !this.text,
        // Outline
        'border-red-600 hover:bg-red-600 text-red-600 hover:text-white': this.error && this.outline,
        // Text
        'hover:bg-red-200 text-red-600': this.error && this.text,
        // Selected
        'bg-red-600 text-white': this.error && this.selected
      }
    }
  }
}
