export default {
  props: {
    errors: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },
  computed: {
    localErrors: {
      get () {
        return this.errors
      },
      set (localValue) {
        this.$emit('update:errors', localValue)
      }
    }
  }
}
