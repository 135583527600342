var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{class:{
    'fixed top-0 left-0 flex justify-between items-center w-full bg-white text-shade-dark h-12 z-10': true,
    'pl-64 transition-all': _vm.expanded,
    'pl-0 transition-all': !_vm.expanded
  }},[_c('div',{staticClass:"flex items-center h-full"},[_c('button',{staticClass:"navigation-menu__toggler flex justify-center items-center h-full px-6",attrs:{"type":"button","aria-label":"Open navigation menu","title":"Open navigation menu"},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'bars'],"size":"lg"}})],1),_c('h1',{staticClass:"font-bold px-2 py-4"},[_vm._v("Vibox")])]),_c('div',{staticClass:"relative"},[_c('a',{staticClass:"flex items-center hover:bg-primary-light cursor-pointer space-x-2 px-6 py-4",on:{"click":function($event){return _vm.setMenu('home')}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'user']}}),(_vm.$auth.user.name)?_c('span',[_vm._v(_vm._s(_vm.$auth.user.name))]):_vm._e()],1),(_vm.navigation === 'home')?_c('div',{staticClass:"static lg:absolute right-0 w-full origin-top lg:origin-top-right lg:shadow-lg lg:w-48"},[_c('div',{staticClass:"px-2 py-2 bg-white lg:rounded-md lg:shadow"},[_c('a',{staticClass:"block text-primary font-medium hover:bg-primary-light p-2 rounded px-3 cursor-pointer",on:{"click":function($event){_vm.$auth.logout(); _vm.resetMenu}}},[_vm._v(" Logout ")]),_c('router-link',{staticClass:"block text-primary font-medium hover:bg-primary-light p-2 rounded px-3 cursor-pointer",attrs:{"to":{
              name: 'account'
            }},nativeOn:{"click":function($event){return _vm.resetMenu.apply(null, arguments)}}},[_vm._v(" Account ")])],1)]):_vm._e()])]),_c('aside',{class:{
    'fixed w-64 h-screen bg-white z-20': true,
    'shadow ml-0 transition-all': _vm.expanded,
    'shadow-none -ml-64 transition-all': !_vm.expanded
  }},[_c('nav',{staticClass:"flex flex-col"},[_c('div',{staticClass:"relative"},[_c('router-link',{staticClass:"flex items-center hover:bg-primary-light px-2 py-6",attrs:{"to":{
            name: 'home'
          }},nativeOn:{"click":function($event){return _vm.resetMenu.apply(null, arguments)}}},[_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":['fas', 'tachometer-alt'],"size":"lg","fixed-width":""}}),_vm._v(" Dashboard ")],1)],1),_c('vue-navigation-item',{attrs:{"unique":"orders","label":"Orders","route":{
          name: 'orders.index'
        },"icon":['fas', 'box']},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"products","label":"Products","add-route":{
          name: 'products.new'
        },"icon":['fas', 'desktop'],"children":[
          { label: 'Products', icon: ['fas', 'desktop'], route: { name: 'products.index' } },
          { label: 'Parts', icon: ['fas', 'microchip'], route: { name: 'parts.index' } },
          { label: 'Assemblies', icon: ['fas', 'boxes'], route: { name: 'assemblies.index' } }
        ],"show-children":_vm.checkMenu('products')},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{staticClass:"hidden",attrs:{"unique":"customers","label":"Customers","icon":['fas', 'users'],"children":[
          { label: 'Customers', icon: ['fas', 'users'], route: { name: 'home' } },
          { label: 'Reports', icon: ['fas', 'chart-bar'], route: { name: 'home' } }
        ],"show-children":_vm.checkMenu('customers')},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"sets","label":"Sets","route":{
          name: 'sets.index'
        },"icon":['fas', 'list-ul']},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"website","label":"Website","icon":['fas', 'globe'],"children":[
          { label: 'Games & Performance', icon: ['fas', 'joystick'], route: { name: 'games.index' } },
          { label: 'Posts', icon: ['fas', 'rss'], route: { name: 'posts.index' } },
          { label: 'Pages', icon: ['fas', 'file'], route: { name: 'pages.index' } },
          { label: 'Menu', icon: ['fas', 'list-ul'], route: { name: 'menus.index' } }
        ],"show-children":_vm.checkMenu('website')},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}}),_c('vue-navigation-item',{attrs:{"unique":"settings","label":"Settings","icon":['fas', 'cogs'],"children":[
          { label: 'Settings', icon: ['fas', 'cogs'], route: { name: 'settings' } },
          { label: 'Gift Cards', icon: ['fas', 'file-download'], route: { name: 'gift-cards.index' }},
          { label: 'Marketplaces', icon: ['fas', 'file-download'], route: { name: 'marketplaces.index' }},
          { label: 'Import', icon: ['fas', 'file-upload'], route: { name: 'import' } },
          { label: 'Export', icon: ['fas', 'file-download'], route: { name: 'export' } }
        ],"show-children":_vm.checkMenu('settings')},on:{"set-menu":_vm.setMenu,"reset-menu":_vm.resetMenu}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }