<template>
  <div class="flex flex-col space-y-4">
    <div class="space-y-2">
      <h2 class="text-2xl">Layout Options</h2>
      <div class="bg-white space-y-2 px-3 py-2">
        <div class="flex flex-wrap w-row-offset-sm space-y-2 -mx-2">
          <div class="w-full px-2">
            <label for="layoutGridCols" class="text-sm font-medium">Column Count</label>
            <div class="flex flex-nowrap space-x-1">
              <div v-for="(width, index) in data.cols" :key="index" class="flex-grow">
                <vue-input v-model="data.cols[index]" type="text" placeholder="Size (px, em, rem, fr, %)" />
              </div>
              <button type="button" class="bg-gray-300 hover:bg-red-600 text-gray-900 hover:text-white rounded-sm px-2 py-1" @click="remove('cols')">
                <font-awesome-icon :icon="['fas', 'minus']" fixed-width />
              </button>
              <button type="button" class="bg-gray-300 hover:bg-green-600 text-gray-900 hover:text-white rounded-sm px-2 py-1" @click="add('cols', '1fr')">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
              </button>
            </div>
          </div>
          <div class="w-full md:w-1/2 px-2">
            <label for="layoutGridCols" class="font-sm text-medium">Row Size</label>
            <vue-input v-model="data.autoRows" type="text" placeholder="Size (px, em, rem, fr, %)" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="item" class="space-y-2">
      <h2 class="text-2xl">Item Options</h2>
      <div class="bg-white rounded px-4 py-2">
        <div class="flex flex-wrap w-row-offset-sm space-y-4 -mx-2">
          <div v-if="elements" class="w-full px-2">
            <label for="itemElement" class="text-sm font-medium">
              Item Layout
            </label>
            <vue-select
              v-model="item.element"
              :options="elements"
              id="itemElement"
              labelKey="name"
              valueKey="key"
            />
          </div>
          <template v-if="element">
            <component
              :is="element"
              :item="item"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { find, merge } from 'lodash'

export default {
  name: 'GridLayoutConfig',

  props: {
    data: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      required: false,
      default: () => { return null }
    },

    elements: {
      type: Array,
      required: true
    }
  },

  computed: {
    item () {
      return this.index !== null ? this.data.items[this.index] : null
    },

    element () {
      const element = find(this.elements, { key: this.item.element })
      return element ? element.config : null
    }
  },

  watch: {
    item (newVal) {
      if (newVal && (!newVal.grid || (newVal.grid && !newVal.grid.colSpan))) {
        this.data.items[this.index] = merge(this.item, { grid: { colSpan: 1 } })
      }
    }
  },

  methods: {
    add (key, value) {
      if (typeof this.data[key] === 'undefined') {
        this.data[key] = []
      }
      this.data[key].push(value)
    },

    remove (key) {
      if (this.data[key].length > 1) {
        this.data[key].splice(-1, 1)
      }
    }
  }
}
</script>
