import Vue from 'vue'

const prefix = 'vue'

Vue.component(
  `${prefix}-errors`,
  require('@/components/VueErrorsCover.vue').default
)

Vue.component(
  `${prefix}-input`,
  require('@/components/forms/VueInput.vue').default
)

Vue.component(
  `${prefix}-input-group`,
  require('@/components/forms/VueInputGroup.vue').default
)

Vue.component(
  `${prefix}-textarea`,
  require('@/components/forms/VueTextArea.vue').default
)

Vue.component(
  `${prefix}-markdown-field`,
  require('@/components/forms/VueMarkdownEditor').default
)

Vue.component(
  `${prefix}-checkbox`,
  require('@/components/forms/VueCheckbox.vue').default
)

Vue.component(
  `${prefix}-checkbox-group`,
  require('@/components/forms/VueCheckboxGroup.vue').default
)

Vue.component(
  `${prefix}-radio-group`,
  require('@/components/forms/VueRadioGroup.vue').default
)

Vue.component(
  `${prefix}-datetime`,
  require('@/components/forms/VueDateTime.vue').default
)

Vue.component(
  `${prefix}-form`,
  require('@/components/forms/VueForm.vue').default
)

Vue.component(
  `${prefix}-select`,
  require('@/components/forms/VueSelect.vue').default
)

Vue.component(
  `${prefix}-file`,
  require('@/components/forms/VueFile.vue').default
)

Vue.component(
  `${prefix}-button`,
  require('@/components/VueButton.vue').default
)

Vue.component(
  `${prefix}-markdown`,
  require('@/components/VueMarkdown').default
)

Vue.component(
  `${prefix}-link`,
  require('@/components/VueLink.vue').default
)

Vue.component(
  `${prefix}-alert`,
  require('@/components/VueAlert.vue').default
)

Vue.component(
  `${prefix}-dialog`,
  require('@/components/VueDialog.vue').default
)

Vue.component(
  `${prefix}-data-table`,
  require('@/components/VueDataTable.vue').default
)

Vue.component(
  `${prefix}-table-filters`,
  require('@/components/tables/VueTableFilters.vue').default
)

Vue.component(
  `${prefix}-navigation`,
  require('@/components/VueNavigation.vue').default
)

Vue.component(
  `${prefix}-navigation-item`,
  require('@/components/VueNavigationItem.vue').default
)

Vue.component(
  `${prefix}-card`,
  require('@/components/VueCard.vue').default
)

Vue.component(
  `${prefix}-data`,
  require('@/components/VueData.vue').default
)
