<template>
<div class="py-2">
  <label class="flex justify-start items-start items-center">
    <div class="bg-white border rounded border-gray-300 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500 hover:border-gray-400">
      <input
        v-model="localValue"
        v-bind="$attrs"
        type="checkbox"
        class="opacity-0 absolute"
        @input="localErrors = []"
      >
      <svg class="fill-current hidden w-3 h-3 text-primary pointer-events-none" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg>
    </div>
    <div
      v-if="label"
      class="select-none text-sm"
    >
      {{ label }}
    </div>
  </label>
  <div v-if="description" class="text-xs opacity-75 mt-2 mb-1">
    {{ description }}
  </div>
  <div>
    <span
      v-for="(error, errorKey) in localErrors"
      :key="errorKey"
      class="text-red-600 text-xs font-medium mr-1">
      {{ error }}
    </span>
  </div>
</div>
</template>
<script type="text/javascript">
import HasVModel from '@/mixins/HasVModel'

export default {
  name: 'VueCheckbox',

  mixins: [HasVModel],

  props: {
    label: {
      type: [Boolean, String],
      required: false,
      default: () => { return null }
    },

    errors: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    description: {
      type: [Boolean, String],
      required: false,
      default: () => { return false }
    }
  },

  computed: {
    localErrors: {
      get () {
        return this.errors
      },
      set (localValue) {
        this.$emit('update:errors', localValue)
      }
    }
  }
}
</script>
<style type="text/css">
  input:checked + svg {
    display: block;
  }
</style>
