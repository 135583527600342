import Vue from 'vue'
import VueRouter from 'vue-router'
import middleware from '@/router/middleware'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "home-view" */ '@/views/Home.vue')
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: middleware.guest,
    component: () => import(/* webpackChunkName: "login-view" */ '@/views/Login.vue')
  },
  {
    path: '/account',
    name: 'account',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "account-view" */ '@/views/Account.vue')
  },
  {
    path: '/single',
    name: 'single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "single-view" */ '@/views/Single.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "settings-view" */ '@/views/settings/Settings.vue')
  },
  {
    path: '/import',
    name: 'import',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "import-view" */ '@/views/import/Import.vue')
  },
  {
    path: '/export',
    name: 'export',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "export-view" */ '@/views/export/Export.vue')
  },
  {
    path: '/customers',
    name: 'customers.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "customers-index" */ '@/views/customers/Customers.vue')
  },
  {
    path: '/customers/reports',
    name: 'customers.reports',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "customers-reports" */ '@/views/customers/CustomerReports.vue')
  },
  {
    path: '/orders',
    name: 'orders.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "orders-index" */ '@/views/orders/Orders.vue')
  },
  {
    path: '/orders/:order',
    name: 'orders.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "orders-single" */ '@/views/orders/OrdersSingle.vue')
  },
  {
    path: '/products',
    name: 'products.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "products-index" */ '@/views/products/Products.vue')
  },
  {
    path: '/products/new',
    name: 'products.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "products-new" */ '@/views/products/ProductsNew.vue')
  },
  {
    path: '/products/:model',
    name: 'products.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "products-single" */ '@/views/products/ProductsSingle.vue')
  },
  {
    path: '/parts',
    name: 'parts.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "parts-index" */ '@/views/parts/Parts.vue')
  },
  {
    path: '/parts/:model',
    name: 'parts.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "parts-single" */ '@/views/products/ProductsSingle.vue')
  },
  {
    path: '/sets',
    name: 'sets.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "sets-index" */ '@/views/sets/Sets.vue')
  },
  {
    path: '/sets/new',
    name: 'sets.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "sets-new" */ '@/views/sets/SetsNew.vue')
  },
  {
    path: '/sets/:model',
    name: 'sets.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "sets-single" */ '@/views/sets/SetsSingle.vue')
  },
  {
    path: '/assemblies',
    name: 'assemblies.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "assemblies-index" */ '@/views/assemblies/Assemblies.vue')
  },
  {
    path: '/assemblies/new',
    name: 'assemblies.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "assemblies-new" */ '@/views/assemblies/AssembliesNew.vue')
  },
  {
    path: '/assemblies/:model',
    name: 'assemblies.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "assemblies-single" */ '@/views/assemblies/AssembliesSingle.vue')
  },
  {
    path: '/games',
    name: 'games.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "games-index" */ '@/views/games/Games.vue')
  },
  {
    path: '/games/new',
    name: 'games.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "games-new" */ '@/views/games/GamesNew.vue')
  },
  {
    path: '/games/:model',
    name: 'games.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "games-single" */ '@/views/games/GamesSingle.vue')
  },
  {
    path: '/pages',
    name: 'pages.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "pages-index" */ '@/views/pages/Pages.vue')
  },
  {
    path: '/pages/new',
    name: 'pages.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "pages-new" */ '@/views/pages/PagesNew.vue')
  },
  {
    path: '/pages/:model',
    name: 'pages.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "pages-single" */ '@/views/pages/PagesSingle.vue')
  },
  {
    path: '/menus',
    name: 'menus.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "menus-index" */ '@/views/menus/Menus.vue')
  },
  {
    path: '/menus/new',
    name: 'menus.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "menus-new" */ '@/views/menus/MenusNew.vue')
  },
  {
    path: '/menus/:model',
    name: 'menus.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "menus-single" */ '@/views/menus/MenusSingle.vue')
  },
  {
    path: '/posts',
    name: 'posts.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "posts-index" */ '@/views/posts/Posts.vue')
  },
  {
    path: '/posts/new',
    name: 'posts.new',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "posts-new" */ '@/views/posts/PostsNew.vue')
  },
  {
    path: '/posts/:model',
    name: 'posts.single',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "posts-single" */ '@/views/posts/PostsSingle.vue')
  },
  {
    path: '/gift-cards',
    name: 'gift-cards.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "posts-single" */ '@/views/gift-cards/GiftCards.vue'),
    middleware
  },
  {
    path: '/marketplaces',
    name: 'marketplaces.index',
    beforeEnter: middleware.auth,
    component: () => import(/* webpackChunkName: "posts-single" */ '@/views/marketplaces/Marketplaces.vue'),
    middleware
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
