<template>
  <div class="tableFilter relative flex items-stretch z-10">
    <vue-button
      primary
      aria-label="filter"
      :icon="['fas', 'filter']"
      icon-size="md"
      @click="toggleMenu"
    >
      Filter
    </vue-button>
    <TableFilterSingle
      v-for="filter in filters" :filter="filter" :key="filter.key"
      @removeFilter="removeFilter"
    />
    <div
      v-if="showMenu"
      class="absolute top-100 left-0 origin-top-left flex flex-col bg-white w-80 overflow-x-hidden rounded shadow -ml-1 mt-1"
    >
      <TableFilterKeys
        v-if="!newFilterKey"
        :filter-keys="filterKeys"
        :filter-key.sync="newFilterKey"
        :show-menu.sync="showMenu"
      />
      <TableFilterOperators
        v-if="newFilterKey && !newFilterOperator"
        :filter-key.sync="newFilterKey"
        :filter-operator.sync="newFilterOperator"
      />
      <TableFilterValues
        v-if="newFilterOperator"
        :filter-key.sync="newFilterKey"
        :filter-operator.sync="newFilterOperator"
        @addFilter="addFilter"
      />
    </div>
  </div>
</template>
<script type="text/javascript">
import TableFilterKeys from '@/components/tables/TableFilterKeys'
import TableFilterOperators from '@/components/tables/TableFilterOperators'
import TableFilterValues from '@/components/tables/TableFilterValues'
import TableFilterSingle from '@/components/tables/TableFilterSingle'

export default {
  name: 'VueTableFilters',

  components: {
    TableFilterKeys,
    TableFilterOperators,
    TableFilterValues,
    TableFilterSingle
  },

  props: {
    show: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    filterKeys: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },

  data () {
    return {
      filters: [],
      newFilterKey: {},
      newFilterOperator: {},
      showMenu: false
    }
  },

  watch: {
    filters () {
      const query = this.$route.query
      const filters = JSON.stringify(this.filters)
      const newQuery = {
        ...query,
        ...{ filters }
      }

      if ((filters !== this.$route.query.filters)) {
        this.$router.push({ name: this.$route.name, query: newQuery })
      }

      this.$emit('update:filters', [...JSON.parse(newQuery.filters), ...this.filters])
    }
  },

  methods: {
    toggleMenu () {
      this.newFilterKey = null
      this.newFilterOperator = null
      this.showMenu = !this.showMenu
    },

    addFilter ({ value, label }) {
      this.filters.push({
        operator: {
          key: this.newFilterOperator.key,
          label: this.newFilterOperator.label
        },
        key: {
          key: this.newFilterKey.key,
          label: this.newFilterKey.label,
          type: this.newFilterKey.type
        },
        value: value,
        label: label
      })

      this.newFilterKey = null
      this.newFilterOperator = null
      this.showMenu = false
    },

    removeFilter (filter) {
      this.filters.splice(this.filters.indexOf(filter), 1)
    }
  }
}
</script>
