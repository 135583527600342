<template>
  <div>
    <header :class="{
      'fixed top-0 left-0 flex justify-between items-center w-full bg-white text-shade-dark h-12 z-10': true,
      'pl-64 transition-all': expanded,
      'pl-0 transition-all': !expanded
    }">
      <div class="flex items-center h-full">
        <button
          type="button"
          class="navigation-menu__toggler flex justify-center items-center h-full px-6"
          aria-label="Open navigation menu"
          title="Open navigation menu"
          @click="expanded = !expanded"
        >
          <font-awesome-icon
            :icon="['fas', 'bars']"
            size="lg"
          />
        </button>
        <h1 class="font-bold px-2 py-4">Vibox</h1>
      </div>
      <div class="relative">
        <a
          class="flex items-center hover:bg-primary-light cursor-pointer space-x-2 px-6 py-4"
          @click="setMenu('home')"
        >
          <font-awesome-icon :icon="['fas', 'user']" />
          <span v-if="$auth.user.name">{{ $auth.user.name }}</span>
        </a>
        <div
          v-if="navigation === 'home'"
          class="static lg:absolute right-0 w-full origin-top lg:origin-top-right lg:shadow-lg lg:w-48"
        >
          <div class="px-2 py-2 bg-white lg:rounded-md lg:shadow">
            <a
              class="block text-primary font-medium hover:bg-primary-light p-2 rounded px-3 cursor-pointer"
              @click="$auth.logout(); resetMenu"
            >
              Logout
            </a>
            <router-link
              :to="{
                name: 'account'
              }"
              class="block text-primary font-medium hover:bg-primary-light p-2 rounded px-3 cursor-pointer"
              @click.native="resetMenu"
            >
              Account
            </router-link>
          </div>
        </div>
      </div>
    </header>
    <aside :class="{
      'fixed w-64 h-screen bg-white z-20': true,
      'shadow ml-0 transition-all': expanded,
      'shadow-none -ml-64 transition-all': !expanded
    }">
      <nav class="flex flex-col">
        <div class="relative">
          <router-link
            :to="{
              name: 'home'
            }"
            class="flex items-center hover:bg-primary-light px-2 py-6"
            @click.native="resetMenu"
          >
            <font-awesome-icon
              :icon="['fas', 'tachometer-alt']"
              size="lg"
              fixed-width
              class="mr-4"
            />
            Dashboard
          </router-link>
        </div>
        <vue-navigation-item
          unique="orders"
          label="Orders"
          :route="{
            name: 'orders.index'
          }"
          :icon="['fas', 'box']"
          @set-menu="setMenu"
          @reset-menu="resetMenu"
        />
        <vue-navigation-item
          unique="products"
          label="Products"
          :add-route="{
            name: 'products.new'
          }"
          :icon="['fas', 'desktop']"
          :children="[
            { label: 'Products', icon: ['fas', 'desktop'], route: { name: 'products.index' } },
            { label: 'Parts', icon: ['fas', 'microchip'], route: { name: 'parts.index' } },
            { label: 'Assemblies', icon: ['fas', 'boxes'], route: { name: 'assemblies.index' } }
          ]"
          :show-children="checkMenu('products')"
          @set-menu="setMenu"
          @reset-menu="resetMenu"
        />
        <vue-navigation-item
          unique="customers"
          label="Customers"
          :icon="['fas', 'users']"
          :children="[
            { label: 'Customers', icon: ['fas', 'users'], route: { name: 'home' } },
            { label: 'Reports', icon: ['fas', 'chart-bar'], route: { name: 'home' } }
          ]"
          :show-children="checkMenu('customers')"
          class="hidden"
          @set-menu="setMenu"
          @reset-menu="resetMenu"
        />
        <vue-navigation-item
          unique="sets"
          label="Sets"
          :route="{
            name: 'sets.index'
          }"
          :icon="['fas', 'list-ul']"
          @set-menu="setMenu"
          @reset-menu="resetMenu"
        />
        <vue-navigation-item
          unique="website"
          label="Website"
          :icon="['fas', 'globe']"
          :children="[
            { label: 'Games & Performance', icon: ['fas', 'joystick'], route: { name: 'games.index' } },
            { label: 'Posts', icon: ['fas', 'rss'], route: { name: 'posts.index' } },
            { label: 'Pages', icon: ['fas', 'file'], route: { name: 'pages.index' } },
            { label: 'Menu', icon: ['fas', 'list-ul'], route: { name: 'menus.index' } }
          ]"
          :show-children="checkMenu('website')"
          @set-menu="setMenu"
          @reset-menu="resetMenu"
        />
        <vue-navigation-item
          unique="settings"
          label="Settings"
          :icon="['fas', 'cogs']"
          :children="[
            { label: 'Settings', icon: ['fas', 'cogs'], route: { name: 'settings' } },
            { label: 'Gift Cards', icon: ['fas', 'file-download'], route: { name: 'gift-cards.index' }},
            { label: 'Marketplaces', icon: ['fas', 'file-download'], route: { name: 'marketplaces.index' }},
            { label: 'Import', icon: ['fas', 'file-upload'], route: { name: 'import' } },
            { label: 'Export', icon: ['fas', 'file-download'], route: { name: 'export' } }
          ]"
          :show-children="checkMenu('settings')"
          @set-menu="setMenu"
          @reset-menu="resetMenu"
        />
      </nav>
    </aside>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'VueNavigation',

  data () {
    return {
      navigation: null,
      expanded: false
    }
  },

  watch: {
    $route: {
      deep: true,
      handler () {
        this.resetMenu()
      }
    }
  },

  methods: {
    setMenu (item) {
      this.navigation = (this.navigation === item ? null : item)
    },

    checkMenu (item) {
      return this.navigation === item
    },

    resetMenu () {
      this.navigation = null
      this.expanded = false
    }
  }
}
</script>
