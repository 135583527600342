<template>
  <div
    :class="{
      'fixed inset-0 flex items-center justify-center transition-opacity duration-300 z-50': true,
      'opacity-100 pointer-events-auto': hasErrors,
      'opacity-0 pointer-events-none select-none': !hasErrors
    }"
  >
    <div class="relative bg-white rounded shadow-xl overflow-hidden min-w-[400px] z-10">
      <header class="flex justify-between items-center bg-red-600 text-white px-6 py-3">
        <h1 class="text-sm tracking-widest font-bold uppercase">
          Error
        </h1>
        <button class="appearance-none px-2 py-1" @click="clear">
          <font-awesome-icon :icon="['fas', 'times']" size="sm" />
        </button>
      </header>
      <div class="px-6 py-4">
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="index"
          >
            {{ error.message }}
          </li>
        </ul>
      </div>
    </div>
    <div class="absolute inset-0 bg-black bg-opacity-75 -z-1" aria-hidden="true" />
  </div>
</template>
<script>
export default {
  name: 'VueErrorsCover',

  computed: {
    hasErrors () {
      return this.errors && this.errors.length > 0
    },

    errors: {
      get () {
        return this.$store.state.error.errors
      },
      set (value) {
        this.$store.commit('error/addError', value)
      }
    }
  },

  methods: {
    clear () {
      this.$store.commit('error/clearErrors')
    }
  }
}
</script>
