<template>
  <button
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="(loading || disabled) ? 'disabled' : false"
    :class="buttonClasses"
  >
    <font-awesome-icon
      v-if="icon && !loading"
      fixed-width
      :size="iconSize"
      :icon="icon"
      :class="{
        'ml-3': $slots.default && flip,
        'mr-3': $slots.default && !flip
      }"
    />
    <font-awesome-icon
      v-if="loading"
      fixed-width
      spin
      :size="iconSize"
      :icon="['fas', 'spinner-third']"
    />
    <slot />
  </button>
</template>
<script type="text/javascript">
import HasButtonStyles from '@/mixins/HasButtonStyles'
export default {
  name: 'VueButton',

  mixins: [
    HasButtonStyles
  ]
}
</script>
