<template>
  <div class="flex flex-col">
    <div
      v-for="item in data"
      :key="item[valueKey]"
      :class="{
        'flex items-center': true,
        'opacity-50 cursor-not-allowed': item.disabled || false
      }"
    >
      <label class="flex flex-grow justify-start items-start items-center py-2">
        <div class="bg-white border rounded-full border-gray-300 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500 hover:border-gray-400">
          <input
            v-model="localValue"
            v-bind="$attrs"
            :value="item[valueKey]"
            :disabled="item.disabled || false"
            type="radio"
            class="opacity-0 absolute"
            @input="localErrors = []"
          >
          <div class="w-3 h-3 bg-gray-800 hidden rounded-full pointer-events-none" />
        </div>
        <div
          v-if="item[labelKey]"
          class="select-none text-sm"
        >
          {{ item[labelKey] }}
        </div>
      </label>
      <span v-if="itemAppend">
        {{ item[itemAppend] }}
      </span>
    </div>
    <div>
      <span
        v-for="(error, errorKey) in localErrors"
        :key="errorKey"
        class="text-red-600 text-xs font-medium mr-1">
        {{ error }}
      </span>
    </div>
  </div>
</template>
<script type="text/javascript">
import HasVModel from '@/mixins/HasVModel'

export default {
  name: 'VueRadioGroup',

  mixins: [HasVModel],

  props: {
    data: {
      type: Array,
      required: true
    },

    labelKey: {
      type: String,
      required: true
    },

    valueKey: {
      type: String,
      required: true
    },

    itemAppend: {
      type: String,
      required: false,
      default: () => { return null }
    },

    errors: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },

  computed: {
    localErrors: {
      get () {
        return this.errors
      },
      set (localValue) {
        this.$emit('update:errors', localValue)
      }
    }
  }
}
</script>
<style type="text/css">
  input:checked + div {
    display: block;
  }
</style>
