<template>
  <div class="flex items-center justify-between rounded bg-white px-3 py-2 ml-2">
    <span>{{ filter.key.label }} {{ filter.operator.label }} <span class="font-bold italic">{{ filter.label }}</span></span>
    <button
      class="flex ml-2"
      alt="Remove filter"
      aria-label="Remove filter"
      @click="$emit('removeFilter', filter)"
    >
      <font-awesome-icon :icon="['fas', 'times-circle']" />
    </button>
  </div>
</template>
<script>
export default {
  name: 'TableFilterSingle',

  props: {
    filter: {
      required: true
    }
  }
}
</script>
