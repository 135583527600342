<template>
  <div class="markdown-render" v-html="result" />
</template>
<script>
import markdownIt from 'markdown-it'

export default {
  props: {
    source: {
      type: String,
      required: false,
      default: () => { return '' }
    }
  },

  data () {
    return {
      md: new markdownIt(), // eslint-disable-line new-cap
      sourceData: this.source
    }
  },

  computed: {
    result () {
      return this.md.render(this.source)
    }
  }
}
</script>
