var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"space-y-2"},[_c('button',{staticClass:"flex items-center justify-between w-full py-1",on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('h4',{staticClass:"text-lg"},[_vm._v("SEO Assessment")]),_c('div',{staticClass:"flex items-center space-x-2"},[(_vm.overallSeoRating)?_c('span',{class:{
          'rounded-full font-bold text-white text-sm uppercase px-2 py-1': true,
          'bg-gray-600': _vm.overallSeoRating === 'feedback',
          'bg-green-700': _vm.overallSeoRating === 'good',
          'bg-yellow-500': _vm.overallSeoRating === 'ok',
          'bg-red-700': _vm.overallSeoRating === 'bad'
        }},[_vm._v(" "+_vm._s(_vm.overallSeoRating)+" ")]):_vm._e(),_c('font-awesome-icon',{class:{
          'transform-gpu transition-transform': true,
          'scale-y-100': !_vm.expanded,
          '-scale-y-100': _vm.expanded
        },attrs:{"icon":['fas', 'chevron-down'],"fixed-width":""}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}],staticClass:"flex flex-col space-y-1"},_vm._l((_vm.results),function(section,resultIndex){return _c('div',{key:resultIndex,staticClass:"flex flex-col space-y-1"},_vm._l((section),function(item,itemIndex){return _c('div',{key:itemIndex,staticClass:"flex flex-col space-y-1"},[_c('div',{class:{
            'border-l-4 bg-white rounded-sm px-2 py-1': true,
            'border-green-600': item.rating === 'good',
            'border-yellow-500': item.rating === 'ok',
            'border-red-600': item.rating === 'bad',
            'border-gray-500': item.rating === 'feedback'
          },domProps:{"innerHTML":_vm._s(item.text)}})])}),0)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }