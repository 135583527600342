<template>
  <div>
    <form
      ref="form"
      autocomplete="off"
      novalidate
      v-bind="$attrs"
      v-on="$listeners"
      @submit.prevent>
      <slot />
    </form>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'VueForm',

  props: {
    form: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      required: false,
      default: () => { return {} }
    }
  },

  data () {
    return {
      valid: false
    }
  },

  computed: {
    internalErrors: {
      get () {
        return this.errors
      },
      set (errors) {
        this.$emit('update:errors', errors)
      }
    }
  },

  methods: {
    validate () {
      this.valid = this.$refs.form.checkValidity()
      this.validateForm()
      return this.valid
    },

    validateForm () {
      var inputs = this.$refs.form.elements
      Object.keys(inputs).forEach((inputKey) => {
        var name = inputs[inputKey].getAttribute('name')
        if (name && !inputs[inputKey].checkValidity()) {
          this.$set(this.internalErrors, name, [inputs[inputKey].validationMessage])
        }
      })
    }
  }
}
</script>
