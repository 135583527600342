var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c('div',{class:{
    'relative flex cursor-pointer': true,
    'bg-primary text-white': _vm.showChildren
  }},[(_vm.route)?_c('router-link',{class:{
        'flex flex-grow items-center transition-colors px-2 py-6': true,
        'hover:bg-primary-light': !_vm.showChildren
      },attrs:{"to":_vm.route},nativeOn:{"click":function($event){return _vm.$emit('reset-menu')}}},[_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":_vm.icon,"size":"lg","fixed-width":""}}),_c('span',[_vm._v(_vm._s(_vm.label))])],1):_c('a',{class:{
        'flex flex-grow items-center cursor-pointer transition-colors px-2 py-6': true,
        'hover:bg-primary-light': !_vm.showChildren
      },on:{"click":function($event){return _vm.$emit('set-menu', _vm.unique)}}},[_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":_vm.icon,"size":"lg","fixed-width":""}}),_c('span',[_vm._v(_vm._s(_vm.label))])],1),(_vm.addRoute)?_c('div',{staticClass:"absolute right-0 flex items-center h-full mr-2"},[_c('router-link',{staticClass:"flex justify-center items-center rounded-full bg-white text-shade-dark hover:bg-primary-light hover:shadow transition-colors px-2 py-2",attrs:{"to":_vm.addRoute,"title":("New " + _vm.label),"aria-label":("New " + _vm.label)},nativeOn:{"click":function($event){return _vm.$emit('reset-menu')}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'plus'],"fixed-width":""}})],1)],1):(!_vm.addRoute && _vm.hasChildren)?_c('div',{staticClass:"absolute right-0 flex items-center h-full pointer-events-none mr-4"},[_c('font-awesome-icon',{class:{
          'transition-transform transform-gpu': true,
          'scale-y-100': !_vm.showChildren,
          '-scale-y-100': _vm.showChildren
        },attrs:{"icon":['fas', 'chevron-down'],"fixed-width":""}})],1):_vm._e()],1),(_vm.hasChildren)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChildren),expression:"showChildren"}],staticClass:"flex flex-col bg-shade-light border-l-4 border-primary w-full"},_vm._l((_vm.children),function(child,index){return _c('router-link',{key:("nav-" + index),staticClass:"flex items-center hover:bg-primary-light transition-colors pl-4 px-2 py-4",attrs:{"to":child.route},nativeOn:{"click":function($event){return _vm.$emit('reset-menu')}}},[(child.icon)?_c('font-awesome-icon',{staticClass:"mr-4",attrs:{"icon":child.icon,"fixed-width":""}}):_vm._e(),_c('span',[_vm._v(_vm._s(child.label))])],1)}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }