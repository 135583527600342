import editorStore from './store/editor.js'
import GridLayoutContainer from './components/GridLayoutContainer'

const VueGridLayoutDesigner = {
  install (Vue, store) {
    Vue.component(GridLayoutContainer.name, GridLayoutContainer)
    store.registerModule('editor', editorStore)
  }
}

export default VueGridLayoutDesigner
