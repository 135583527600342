<template>
  <div class="flex flex-col">
    <template v-if="$attrs.type && $attrs.type === 'file'">
      <label v-if="label" class="font-medium text-sm mb-1">
        {{ label }}
      </label>
      <figure
        :class="{
          'aspect-w-1 aspect-h-1 rounded-sm overflow-hidden': true,
          'bg-alpha': (value && value !== undefined) || newFile,
          'bg-white': !value || !newFile
        }"
      >
        <img
          v-if="(value && value !== undefined) || newFile"
          :src="newFile ? newFileSrc : ($attrs.imageSize ? value[$attrs.imageSize] : value.medium)"
          ref="imagePreview"
          class="valid-image object-contain px-4 py-4"
        >
        <span v-else class="no-image flex justify-center items-center text-gray-600 text-sm px-4 py-4">
          No image selected
        </span>
      </figure>
      <div v-if="edit" class="flex justify-between mt-2">
        <input
          v-bind="$attrs"
          ref="file"
          @change="setFile"
        >
        <!--
          <vue-button
            v-if="value"
            :icon="['fad', 'trash']"
            text
            xs
            @click="discardFile"
          />
        -->
      </div>
    </template>
    <template v-else-if="$attrs.type && $attrs.type === 'checkbox'">
      <label v-if="label && !edit" class="font-medium text-sm mb-1">
        {{ label }}
      </label>
      <div
        v-if="edit"
        :class="{
          'w-full rounded-sm py-1': true,
          'border border-shade px-2': border
        }"
      >
        <vue-checkbox
          v-if="edit"
          v-model="localValue"
          v-bind="$attrs"
          :value="value"
          :label="label"
          :disabled="!edit"
        />
      </div>
      <data
        v-else
        :value="value"
        :class="{
          'w-full rounded-sm py-1': true,
          'border border-shade px-2': border
        }"
      >
        <font-awesome-icon
          :icon="['fas', value ? 'check-circle' : 'times-circle']"
          :class="{
            'text-green-700': value,
            'text-red-700': !value
          }"
          size="lg"
          fixed-width
        />
      </data>
    </template>
    <template v-else-if="$attrs.type && $attrs.type === 'checkbox-group'">
      <label v-if="label && !edit" class="font-medium text-sm mb-1">
        {{ label }}
      </label>
      <vue-checkbox-group
        v-if="edit"
        v-model="localValue"
        v-bind="$attrs"
        :data="options"
      />
      <data
        v-else
        :class="{
          'w-full rounded-sm py-1': true,
          'border border-shade px-2': border
        }"
      >
        <ul v-if="localValue && localValue.length > 0">
          <li v-for="(item, index) in localValue" :key="index">
            {{ item }}
          </li>
        </ul>
        <span v-else>
          N/A
        </span>
      </data>
    </template>
    <template v-else-if="$attrs.type && $attrs.type === 'datetime'">
      <label v-if="label" class="font-medium text-sm mb-1">
        {{ label }}
      </label>
      <vue-datetime
        v-if="edit"
        v-model="localValue"
        v-bind="$attrs"
        :prop="$attrs.prop"
        :value="value"
        :config="options"
      />
      <data
        v-else
        :value="value ? value : null"
        :class="{
          'w-full rounded-sm py-1': true,
          'border border-shade px-2': border
        }"
      >
        {{ value ? $momentDateTime(value) : 'Not Published' }}
      </data>
    </template>
    <template v-else-if="$attrs.type && $attrs.type === 'select'">
      <label v-if="label" class="font-medium text-sm mb-1">
        {{ label }}
      </label>
      <vue-select
        v-if="edit"
        v-model="localValue"
        v-bind="$attrs"
        :value="value"
        :options="options"
        :dropdownLabel="dropdownLabel"
        :labelKey="labelKey"
        :valueKey="valueKey"
      />
      <data
        v-else
        :value="value ? (valueKey ? value[valueKey] || value : value) : null"
        :class="{
          'w-full rounded-sm py-1': true,
          'border border-shade px-2': border
        }"
      >
        <ul v-if="typeof value !== 'string' && value.length > 0" class="list-disc list-inside">
          <li v-for="(val, index) in value" :key="index">
            {{ val }}
          </li>
        </ul>
        <span v-else>{{ value ? (labelKey ? value[labelKey] || (optionValue ? optionValue.label : value) : value) : 'N/A' }}</span>
      </data>
    </template>
    <template v-else-if="$attrs.type && $attrs.type === 'markdown'">
      <label v-if="label" class="font-medium text-sm mb-1">
        {{ label }}
      </label>
      <vue-markdown-field
        v-if="edit"
        v-model="localValue"
        v-bind="$attrs"
        :value="value"
      />
      <data
        v-else
        v-html="value || 'N/A'"
        :value="value"
        :class="{
          'markdown-render w-full rounded-sm py-1': true,
          'border border-shade px-2': border
        }"
      />
    </template>
    <template v-else>
      <label v-if="label" class="font-medium text-sm mb-1">
        {{ label }}
      </label>
      <vue-textarea
        v-if="$attrs.type && $attrs.type === 'textarea' && edit"
        v-model="localValue"
        v-bind="$attrs"
      />
      <vue-input
        v-else-if="edit"
        v-model="localValue"
        v-bind="$attrs"
        :prepend="prepend"
        :append="append"
        :value="value"
      />
      <template v-if="!edit">
        <div
          v-if="$attrs.type && $attrs.type === 'markdown'"
          :class="{
            'w-full rounded-sm py-1': true,
            'border border-shade px-2': border
          }"
        >
          <vue-markdown
            v-if="value"
            :source="value"
          />
          <span v-else>
            N/A
          </span>
        </div>
        <data
          v-else
          :value="value"
          :class="{
            'flex items-center w-full rounded-sm': true,
            'border border-shade': border
          }"
        >
          <span
            v-if="value && prepend"
            class="bg-gray-200 py-1 px-3"
          >
            {{ prepend }}
          </span>
          <span
            :class="{
              'flex-grow py-1': true,
              'px-2': border
            }"
          >
            {{ value ? value : 'N/A' }}
          </span>
          <span
            v-if="value && append"
            class="bg-gray-200 py-1 px-3"
          >
            {{ append }}
          </span>
        </data>
      </template>
    </template>
    <div v-if="description" class="text-xs opacity-75 mt-1">
      {{ description }}
    </div>
  </div>
</template>
<script>
import { find } from 'lodash'
import HasVModel from '@/mixins/HasVModel'

export default {
  name: 'VueData',

  mixins: [HasVModel],

  data () {
    return {
      newFile: false,
      newFileSrc: null
    }
  },

  props: {
    label: {
      type: [Boolean, String],
      required: false,
      default: () => { return false }
    },

    description: {
      type: [Boolean, String],
      required: false,
      default: () => { return false }
    },

    edit: {
      type: Boolean,
      required: false,
      default: () => { return false }
    },

    options: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

    labelKey: {
      type: [String, Function],
      required: false,
      default: () => { return null }
    },

    valueKey: {
      type: [String, Function],
      required: false,
      default: () => { return null }
    },

    dropdownLabel: {
      type: [Boolean, Function],
      required: false,
      default: () => { return false }
    },

    prepend: {
      required: false,
      default: () => { return null }
    },

    append: {
      required: false,
      default: () => { return null }
    },

    border: {
      required: false,
      default: () => { return true }
    }
  },

  computed: {
    optionValue () {
      return this.options && this.valueKey ? find(this.options, { [this.valueKey]: this.value }) || this.value : this.value
    }
  },

  watch: {
    edit () {
      this.newFile = false
      this.newFileSrc = null
    }
  },

  methods: {
    setFile (event) {
      const file = this.$refs.file.files[0]
      this.newFile = true
      this.$emit('update', file)
      this.preview(file)
    },

    discardFile () {
      this.$refs.file.value = ''
      this.newFile = false
      this.$emit('discard')
    },

    deleteFile () {
      console.log('test')
    },

    preview (file) {
      const reader = new FileReader()
      if (file) {
        reader.readAsDataURL(file)
      }
      reader.addEventListener('load', () => {
        this.newFileSrc = reader.result
      })
    }
  }
}
</script>
