<template>
  <div class="bg-white w-full rounded">
    <div class="flex flex-col px-1 py-1">
      <vue-button
        text
        sm
        @click.native="$emit('update:filter-operator', null)"
      >
        Back
      </vue-button>
      <vue-input-group
        v-if="filterKey.type === 'String'"
        v-model="filter"
        :label="label"
        prop="string"
        class="mb-1"
      />

      <vue-select
        v-if="filterKey.type === 'Select'"
        v-model="filter"
        :options="filterKey.values"
        :label="label"
        label-key="label"
        value-key="key"
        class="mb-1"
      />

      <vue-button
        :disabled="!filter"
        sm
        class="mb-1"
        @click="$emit('addFilter', { value: filter, label: getFilterLabel() })"
      >
        Apply filter
      </vue-button>
    </div>
  </div>
</template>
<script type="text/javascript">
import { find } from 'lodash'

export default {
  name: 'TableFilterValues',

  props: {
    filterKey: {
      required: true
    },

    filterOperator: {
      required: true
    }
  },

  data () {
    return {
      filter: null
    }
  },

  computed: {
    label () {
      return `${this.filterKey.label} ${this.filterOperator.label}...`
    }
  },

  methods: {
    getFilterLabel () {
      const result = find(this.filterKey.values, { key: this.filter })
      return result ? result.label : this.filter
    }
  }
}
</script>
